import styles from "./Product.module.scss";
import useProduct from "./useProduct";
import Checkbox from "../../UI/Checkbox/Checkbox";

const Product = ({ className, ...props }) => {
  const {
    sku,
    name,
    type,
    price,
    attributes,
    isChecked,
    onCheckboxChange,
    attributeUnit,
  } = props;
  const attributesView = useProduct(type, attributes, attributeUnit);
  const changeHandler = () => {
    onCheckboxChange(!isChecked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.checkbox}>
        <Checkbox checkHandler={changeHandler} isChecked={isChecked}>
          <span></span>
        </Checkbox>
      </div>
      <p>{sku}</p>
      <p>{name}</p>
      <p>{price} $</p>
      <p>{attributesView}</p>
    </div>
  );
};
export default Product;
