import React, { useEffect, useState } from "react";
import Product from "../Product";
import styles from "./ProductList.module.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../../UI/Button/Button";

const ProductList = () => {
  const [checkedProductIds, setCheckedProductIds] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const handleCheckboxChange = (id, isChecked) => {
    setCheckedProductIds((prevCheckedIds) => {
      if (isChecked) {
        return [...prevCheckedIds, id];
      } else {
        return prevCheckedIds.filter((productId) => productId !== id);
      }
    });
  };

  useEffect(() => {
    fetch("https://irm.onlythemagic.com/products")
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => b.id - a.id);
        setProducts(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const handleMassDelete = async () => {
    const deleteUrl = "https://irm.onlythemagic.com/products/delete";

    try {
      const response = await fetch(deleteUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: checkedProductIds }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      setProducts((prevProducts) =>
        prevProducts.filter(
          (product) => !checkedProductIds.includes(product.id),
        ),
      );

      setCheckedProductIds([]);
    } catch (error) {
      console.error("Failed to delete products:", error);
    }
  };

  const handleAddProductLink = () => {
    navigate("/add-product");
  };
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h1>Product List</h1>
        <div className={styles.buttonsContainer}>
          <button
            className={`${styles.button} ${styles.success} ${styles.primary}`}
            onClick={handleAddProductLink}
          >
            ADD
          </button>
          <button
            id="delete-product-btn"
            className={`${styles.button} ${styles.danger} ${styles.primary}`}
            onClick={handleMassDelete}
          >
            MASS DELETE
          </button>
        </div>
      </div>

      <div className={styles.products}>
        {products.map((product, index) => {
          return (
            <Product
              className={styles.productItem}
              key={product.id}
              title={product.type}
              sku={product.sku}
              name={product.name}
              attributes={product.attributes}
              type={product.type}
              price={product.price}
              attributeUnit={product.attributeUnit}
              isChecked={checkedProductIds.includes(product.id)}
              onCheckboxChange={(isChecked) =>
                handleCheckboxChange(product.id, isChecked)
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductList;
