import React, { useState, useEffect } from "react";
import styles from "./Checkbox.module.scss";

const Checkbox = (props) => {
  const { children, checkHandler, isChecked, isSubmitted = false } = props;

  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      setIsTouched(true);
    }
  }, [isSubmitted]);

  return (
    <label
      className={`delete-checkbox ${styles.checkbox} ${isChecked && styles.checked}`}
      onClick={() => {
        setIsTouched(true);
        checkHandler();
      }}
    >
      {children}
    </label>
  );
};
export default Checkbox;
