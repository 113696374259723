import ProductList from "./components/Product/ProductList/ProductList";
import Footer from "./components/Footer/Footer";
import styles from "./App.module.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AddProduct from "./components/Product/AddProduct/AddProduct";

function App() {
  return (
    <Router>
      <div className={styles.container}>
        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="/add-product" element={<AddProduct />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
