import styles from "./TextField.module.scss";

const TextField = (props) => {
  const {
    id,
    label,
    value,
    placeholder,
    onChange,
    error,
    touched,
    type,
    name,
  } = props;

  return (
    <div className={styles.container}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <input
        id={id}
        type={type || "text"}
        value={value || ""}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && touched && <div className={styles.message}>{error}</div>}
    </div>
  );
};

export default TextField;
