import TextField from "../../../UI/Field/TextField/TextField";
import SelectField from "../../../UI/Field/SelectField/SelectField";
import styles from "./AddProduct.module.scss";
import { useEffect, useState } from "react";

const Form = (props) => {
  const { form, data, onSelectedItemChange } = props;
  const { values, handleChange, handleBlur, errors, touched } = form;

  useEffect(() => {
    const selectedItem = data.find(
      (item) =>
        item.name.trim().toLowerCase() === values.type.trim().toLowerCase(),
    );

    onSelectedItemChange(selectedItem);
  }, [values.type, data, onSelectedItemChange]);

  const renderAttributeFields = () => {
    const selectedItem = data.find(
      (item) =>
        item.name.trim().toLowerCase() === values.type.trim().toLowerCase(),
    );

    if (selectedItem) {
      return (
        <div>
          {selectedItem.attributes.map((attr, index) => (
            <>
              {touched[attr.name.toLowerCase()] &&
                errors[attr.name.toLowerCase()] && (
                  <div className={styles.error}>
                    {errors[attr.name.toLowerCase()]}
                  </div>
                )}
              <TextField
                key={attr.id}
                label={`${attr.name} (${selectedItem.units})`}
                id={attr.name.toLowerCase()}
                name={attr.name.toLowerCase()}
                type="text"
                value={values[attr.name.toLowerCase()] || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </>
          ))}
          <p className={styles.description}>{selectedItem.description}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <form id={"product_form"}>
      <div className={styles.formContainer}>
        {touched.sku && errors.sku && (
          <div className={styles.error}>{errors.sku}</div>
        )}
        <TextField
          label={"SKU"}
          id={"sku"}
          name={"sku"}
          type={"text"}
          value={values.sku}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div>
          {touched.name && errors.name && (
            <div className={styles.error}>{errors.name}</div>
          )}
          <TextField
            label={"Name"}
            id={"name"}
            name={"name"}
            type={"text"}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        {touched.price && errors.price && (
          <div className={styles.error}>{errors.price}</div>
        )}
        <TextField
          label={"Price ($)"}
          id={"price"}
          name={"price"}
          type={"text"}
          value={values.price}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div>
        {touched.type && errors.type && (
          <div className={styles.error}>{errors.type}</div>
        )}
        <SelectField
          id={values.type ? values.type : "productType"}
          name={"type"}
          label={"Type Switcher"}
          value={values.type}
          onChange={handleChange}
          options={[...values.types]}
          onBlur={handleBlur}
        />
        {renderAttributeFields()}
      </div>
    </form>
  );
};

export default Form;
