import React, { useState } from "react";
import styles from "./AddProduct.module.scss";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import useForm from "./useForm";
import Form from "./Form";
import Button from "../../../UI/Button/Button";

const AddProduct = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [dublicatedSkuError, setDublicateSkuError] = useState(null);
  const handleSelectedItemChange = (item) => {
    setSelectedItem(item);
  };
  const { validationSchema, initialValues } = useForm(selectedItem);

  const navigate = useNavigate();
  const handleCreateProduct = async (values, { setSubmitting }) => {
    const apiUrl = "https://irm.onlythemagic.com/products/create";

    const selectedItem = initialValues.types.find(
      (item) =>
        item.name.trim().toLowerCase() === values.type.trim().toLowerCase(),
    );
    let attributes = {};
    if (selectedItem && selectedItem.attributes) {
      selectedItem.attributes.forEach((attr) => {
        attributes[attr.name] = parseFloat(values[attr.name.toLowerCase()]);
      });
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: values.type.toLowerCase(),
          name: values.name,
          sku: values.sku,
          price: parseFloat(values.price),
          category_id: selectedItem.id,
          attributes: attributes,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        if (errorText.includes("Duplicate entry")) {
          setDublicateSkuError("Sku already exists");
          setTimeout(() => {
            setDublicateSkuError(null);
          }, 3000);
        }
        throw new Error(
          `Server responded with ${response.status}: ${errorText}`,
        );
      }

      navigate("/");
    } catch (error) {
      console.error("Failed to create product:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleCreateProduct}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {(formikProps) => (
          <>
            <div className={styles.headerContainer}>
              <h1>Product List</h1>
              <div className={styles.buttonContainer}>
                <button
                  className={`${styles.button} ${styles.success} ${styles.primary}`}
                  onClick={formikProps.submitForm}
                >
                  Save
                </button>
                <button
                  className={`${styles.button} ${styles.danger} ${styles.primary}`}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
            {dublicatedSkuError && (
              <p className={styles.error}>{dublicatedSkuError}</p>
            )}
            <Form
              form={formikProps}
              data={initialValues.types}
              onSelectedItemChange={handleSelectedItemChange}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
export default AddProduct;
