import * as Yup from "yup";
import { useEffect, useState } from "react";
const useForm = (selectedItem) => {
  const [categories, setCategories] = useState([]);

  let validationSchema = Yup.object().shape({
    sku: Yup.string()
      .required("Please, submit required data")
      .min(
        2,
        "Please, provide the data of indicated type. SKU must be at least 2 characters",
      ),
    name: Yup.string()
      .required("Please, submit required data")
      .min(
        2,
        "Please, provide the data of indicated type. Name must be at least 2 characters",
      ),
    price: Yup.number()
      .required("Please, submit required data")
      .typeError(
        "Please, provide the data of indicated type. Price must be a valid number",
      )
      .positive(
        "Please, provide the data of indicated type. Price must be a positive number",
      ),
    type: Yup.string().required("Type is required"),
  });

  if (selectedItem) {
    selectedItem.attributes.forEach((attr) => {
      validationSchema = validationSchema.shape({
        [attr.name.toLowerCase()]: Yup.number()
          .typeError(
            `Please, provide the data of indicated type. ${attr.name} must be a valid number`,
          )
          .required("Please, submit required data")
          .positive(
            `Please, provide the data of indicated type. ${attr.name} must be a positive number`,
          ),
      });
    });
  }

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://irm.onlythemagic.com/categories");
        if (!response.ok) {
          throw new Error("Failed to fetch categories");
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const initialValues = {
    sku: "",
    price: "",
    type: "",
    name: "",
    types: categories,
  };

  return { validationSchema, initialValues };
};

export default useForm;
