import styles from "./SelectField.module.scss";
import { useEffect, useState } from "react";

const SelectField = (props) => {
  const {
    id,
    label,
    value,
    placeholder,
    onChange,
    error,
    touched,
    type,
    name,
    options,
  } = props;

  const [selectValue, setSelectValue] = useState();

  useEffect(() => {
    if (!selectValue || selectValue !== value) {
      setSelectValue(
        value === "" ? options?.find((option) => option)?.value : value,
      );
    }
  }, [value]);

  return (
    <div className={styles.container}>
      <label htmlFor={id}>{label}</label>
      <select id={id} value={value || ""} name={name} onChange={onChange}>
        <option value="">Type Switcher</option>{" "}
        {/* Optional: Add a default "Select" option */}
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {error && touched && <div className={styles.message}>{error}</div>}
    </div>
  );
};

export default SelectField;
