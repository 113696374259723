import { useMemo } from "react";

const useProduct = (type, attributes, attributeUnit) => {
  const attributesArray = Object.entries(attributes);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  return useMemo(() => {
    if (type.toLowerCase() === "furniture") {
      const dimensions = attributesArray
        .map(([key, value]) => `${value}`)
        .join("X");
      return `Dimensions: ${dimensions}`;
    } else {
      return attributesArray.map(
        ([key, value]) =>
          `${capitalizeFirstLetter(key)}: ${value}${attributeUnit}`,
      );
    }
  }, [type, attributes, attributeUnit]);
};

export default useProduct;
